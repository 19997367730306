.App {
  background-color: #282c34;
  min-height: 100%; 
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 2em;
  overflow: auto;
}

.App-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.App-logo {
  height: 80px;
}

h2 {
  margin-bottom: .5em;
  font-size: 1.75rem;
}

h3 {
  margin-bottom: .25em;
  font-size: 1.25rem;
}

h2:not(:first-child) {
  margin-top: 2em;
}

p,
li {
  font-size: 1rem;
  max-width: 600px;
  overflow: auto;
}

ul {
  padding: 0;
}

ul li {
  margin-bottom: .5em;
}

button {
  border: none;
  padding: .75em 1.5em;
  background-color: #2687ff;
  color: #fff;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
}

code {
  background: #000;
  padding: 0.25em;
  display: inline-block;
}

.variable {
  color: gold;
}


